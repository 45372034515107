// Global Site Navigation
.site-navbar {
  top: 22px;
  background-color: $lynch;
  color: $white;
  padding: 0;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .container {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    flex-flow: row wrap;
    position: relative;
    top: 0;
  }
}

.navbar-brand {
  font-size: $font-size-base;
  text-align: center;
  width: 100%;
  padding: 12px 0;
  @include no-transition;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  img {
    width: 186px;
    height: 30px;
    vertical-align: top;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: 420px;
      height: 35px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, sm)){
      padding: 12px 0 12px 2px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)){
    padding: 12px 0;
    width: 65%;
  }

  @media (min-width: map-get($grid-breakpoints, xl)){
    padding: 16px 0 8px 0;
  }
}

.navbar-toggler {
  padding: 4px;
  color: $white;
  font-size: $font-size-base;
  border-radius: $border-radius-circle;
  background-color: $orange;
  position: absolute;
  right: 10px;
  top: 60%;
  z-index: 1030;

  @media (min-width: map-get($grid-breakpoints, sm)){
    top: 65%;
    right: 15px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)){
    top: 78%;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox='0%200%2030%2030'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cpath%20stroke='rgba(255,%20255,%20255,%201)'%20stroke-width='2'%20stroke-linecap='round'%20stroke-miterlimit='10'%20d='M4%207h22M4%2015h22M4%2023h22'/%3E%3C/svg%3E");
  }
}

.nav-account {
  padding-top:10px;
  float: right;

  a{
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    font-size:.875rem;

    &:hover,
    &.active {
      color: $orange;
    }

    &.account {
      background-color: $orange;
      padding: .125rem .25rem;
      border-radius: $border-radius-sm;
      color: $white;

      &:hover,
      &.active {
        color: $white;
        background-color: $um-orange;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)){
    padding-top:20px;

    a {
      color: $white;

      &:hover,
      &.active {
        color: $orange;
      }
    }
  }
}



.navbar-nav {

  &.site-nav {
    margin: 1rem 0;

    .nav-item{
      padding: 0 0 1rem 1rem;

      &.active {
        .nav-link {
          color: $orange;
        }
      }

      &.dropdown {
        &.show {
          .fa-chevron-circle-down {
            &:before{
              content: "\f139";
            }
          }
        }
      }
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      margin: 0;

      .nav-item{
        padding: 0;

        &.dropdown {
          position: static;

          &.show {
            .nav-link {
              color: $orange;
              border-bottom: 4px solid $orange;
            }
          }
        }
      }
    }

    .nav-link {
      color: $white;
      text-transform: uppercase;
      line-height: 1rem;
      @include no-transition;
      font-weight: 600;

      &:hover {
        color: $orange;
      }

      @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: .875rem 0 .625rem 0;
        border-bottom: 4px solid transparent;
        color: $white;

        &:hover {
          border-bottom: 4px solid $orange;
          color: $orange;
        }
      }
    }
  }

    .dropdown-toggle {
    &:after {
      border-top: .3em transparent;
      margin-left: 0;
      border-left: none;
    }
  }

    .dropdown-menu{
    background-color: $lynch;
    border: none;
    border-radius: 0;
    color: $white;
    margin: 0 -5px 0 -1rem;
    padding: 0 1rem 0 0;
    -moz-box-shadow: 0 2px 2px -2px $gray-600;
    -webkit-box-shadow: 0 2px 2px -2px $gray-600;
    box-shadow: 0 2px 2px -2px $gray-600;


    @media (min-width: map-get($grid-breakpoints, xl)) {
      width: 100%;
      background-color: $gray-100;
      color: $body-color;
      margin: 0 -5px 0 0;
      padding: 0;
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width:1200px) and (max-height: 900px) {
  .navbar-nav {
    .dropdown-menu {
      max-height: 475px;
      overflow: auto;
    }
  }
}



.nav-icon {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 0 auto;
  padding: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 40%;
    background: $white;
    margin-left: 3px;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(even) {
      left: 40%;
      border-radius: 0 4px 4px 0;
    }

    &:nth-child(odd) {
      left:0px;
      border-radius: 4px 0 0 4px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      top: 4px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 13px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 22px;
    }
  }

  &.open-nav-icon {
    span {

      &:nth-child(1),
      &:nth-child(6) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(2),
      &:nth-child(5) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(1) {
        left: 2px;
        top: 10px;
      }

      &:nth-child(2) {
        left: -webkit-calc(40% - 2px);
        left: -moz-calc(40% - 2px);
        left: calc(40% - 2px);
        top: 10px;
      }

      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }

      &:nth-child(4) {
        left: 100%;
        opacity: 0;
      }

      &:nth-child(5) {
        left: 2px;
        top: 18px;
      }

      &:nth-child(6) {
        left: -webkit-calc(40% - 2px);
        left: -moz-calc(40% - 2px);
        left: calc(40% - 2px);
        top: 18px;
      }


    }
  }

}

.navbar{

  &.mobile {
    padding:0;

    .offcanvas-nav {
      width: 100%;
    }
  }
}

