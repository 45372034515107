// Sections
.section {
  padding: $padding-sm 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: $padding-lg 0;

    &.section-off-top{
      padding: 0 0 $padding-lg 0;
    }

    &.section-off-bottom{
      padding: $padding-lg 0 0 0;
    }

    &.section-half-top{
      padding: ($padding-lg / 2) 0 $padding-lg 0;
    }

    &.section-half-bottom {
      padding: $padding-lg 0 ($padding-lg / 2) 0;
    }

    &.section-half {
      padding: ($padding-lg / 2) 0;
    }

  }
}

.section-minimal {
  padding: .75rem 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 1.5rem 0 3.75rem 0;
  }
}

.section-minimal-nosearch {
  padding: .75rem 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 1.5rem 0;
  }
}

.section-standard {
  padding: .75rem 0 0 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 1.5rem 0 0 0;
  }
}

.section-standard {
  padding: .75rem 0 0 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 1.5rem 0 0 0;
  }
}
