// For transitions
@mixin transition-all() {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

@mixin no-transition() {
	-webkit-transition-property: none;
	-moz-transition-property: none;
	-o-transition-property: none;
	transition-property: none;
}

// Reset and remove styles
@mixin reset-styles() {
	font-family: $font-family-sans-serif;
	font-size: $font-size-base;
	line-height: $line-height-lg;
	margin: 0;
	padding: 0;
	border: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0 !important;
	text-decoration: none;
	color: $body-color;
	background: none transparent no-repeat;
	outline: 0;
	font-weight: normal;
	float: none;
	list-style: none;

	@media (min-width: map-get($grid-breakpoints, lg)) {
		font-size: 1.125rem;
		line-height: $line-height-lg;
	}
}