// External Custom

.feature {
  background-color: $feature-light;
}

.feature-light {
  background-color: $gray-100;
}

.credit {
  text-align: center;
  font-size: .875rem;
  margin-bottom: 0;
}

//Footer
footer {
  margin-bottom: 0;
  position: relative;
  height: auto;
}