// Closing trigger

.panel-close {
  position: absolute;
  top: 0;
  right: 2px;

  .close-mm {
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 0.75em;
    height: 0.75em;
    position: relative;
    border: none;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    font-size: 2.5rem;
    color: rgba(0,0,0,1);
    -o-text-overflow: clip;
    text-overflow: clip;
    background-color: $orange;
    margin-top: -1.5rem;
    box-shadow: 0 2px 2px -2px $gray-600;
    cursor: pointer;

    &:before,
    &:after {
      display: inline-block;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      width: 0.45em;
      height: 0.1em;
      position: absolute;
      content: "";
      top: 0.33em;
      left: 0.155em;
      border: none;
      font-size: 100%;
      color: rgba(0,0,0,1);
      -o-text-overflow: clip;
      text-overflow: clip;
      background: $white;
      text-shadow: none;
    }

    &:before {
      -webkit-transform: rotateZ(45deg);
      transform: rotateZ(45deg);
    }

    &:after {
      -webkit-transform: rotateZ(-45deg);
      transform: rotateZ(-45deg);
    }
  }
}

// Full band

.full-band {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 5px;
}

.clear-band {
  margin-bottom: -1.25rem;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-bottom: -2rem;
  }
}

.band-bkg {
  @media (min-width: map-get($grid-breakpoints, md)) {
    background-image: url('../assets/common-images/band-bkg2.png');
    background-attachment: scroll;
    background-position: right bottom;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    background-position: right center;
  }
  @media (min-width: map-get($grid-breakpoints, xl2)) {
    background-position: center center;
  }
}

// Special Lists
.letterhead-list {
  margin: 1rem;

  li {
    margin-right: 1rem;
    margin-bottom: .75rem;
    font-weight: $font-weight-semi;
    line-height: $line-height-sm;
    font-size: 1rem;

    a {
      padding: .75rem;
      border-radius: $border-radius;
      background-color: $gray-200;
      display: inline-block;

      &:hover,
      &:focus {
        background-color: $gray-400;
      }
    }
  }
}

.popular-list {
  padding: 1rem;
  margin: 0 0 1rem 0;
  text-align: center;
  border-radius: $border-radius;

  ul {
    text-align: left;
    list-style: none;
    padding: 0;
    font-size: 1rem;
  }

  a {
    color: $body-color;
    text-decoration: none;

    &.btn-default {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
      }
    }

    &:hover,
    &:focus {
      color: $um-orange;
    }
  }

  p {
    font-size: 1rem;
    line-height: $line-height-sm;
  }
}

.people-custom-flex-list,
.img200-custom-flex-list {
  padding-left: 0;
  list-style: none;

  @media (min-width: map-get($grid-breakpoints, md)) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  li {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media (min-width: map-get($grid-breakpoints, md)) {
      text-align: left;
      width: 100%;
      margin-bottom: 1.75rem;
      padding-right:.75rem;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > a {
      display: inline-block;
    }

    img {
      border: 1px solid #dee2e6;
      border-radius: $border-radius-sm;
      background-color: #ffffff;
      padding:.25rem;
      height: min-content;
    }

    .list-details {
      width: calc(100% - 110px); //img width 80px
      margin-left: 1.25rem;
      align-self: center;

      @media (min-width: map-get($grid-breakpoints, md)) {
        align-self: flex-start;
      }

      p {
        line-height: 1.2;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

    }
  }
}

.img200-custom-flex-list {
  li {
    .list-details {
      width: calc(100% - 230px); // img width 200px
    }
  }
}

.event-custom-flex-list {
  padding-left: 0;
  list-style: none;

  li {
    margin-bottom: 2.5rem;
    text-align: center;

    @media (min-width: map-get($grid-breakpoints, md)) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      border: 1px solid #dee2e6;
      border-radius: $border-radius-sm;
      background-color: #ffffff;
      padding:.25rem;
      width: 350px;
      height: min-content;
    }

    .list-details {
      width: 100%;
      text-align: left;
      margin-top: 1rem;

      @media (min-width: map-get($grid-breakpoints, md)) {
        width: calc(100% - 380px); // img width 350px
        align-self: flex-start;
        margin-top: 0;
        margin-left: 1.25rem;
      }
      @media (min-width: map-get($grid-breakpoints, xl)) {
        padding-right: 1rem;
      }

      p {
        line-height: 1.2;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

    }
  }
}


// Maps
.maps {
  width: 100%;
  min-height: 275px;
  border: 1px solid $border-color;
  margin: 1rem 0 2rem 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    min-height: 450px;

    &.small-map {
      min-height: 250px;
    }
  }
}

//Special Color Bands
.purple-gradient {
  background: -moz-linear-gradient(185deg, #9e809b 0%, #5d6677 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #5d6677), color-stop(100%, #9e809b)); /* safari4+,chrome */
  background: -webkit-linear-gradient(185deg, #9e809b 0%, #5d6677 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(185deg, #9e809b 0%, #5d6677 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(185deg, #9e809b 0%, #5d6677 100%); /* ie10+ */
  background: linear-gradient(265deg, #9e809b 0%, #5d6677 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5d6677', endColorstr='#9e809b',GradientType=1 ); /* ie6-9 */

  color: $white;

  .carousel-band {
    .carousel-control-prev,
    .carousel-control-next {
      color: $white;
      opacity: .6;
    }

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      .carousel-indicators {
        li {
          background-color: $gray-300;
        }

        .active{
          background-color: $white;
        }
      }
    }
  }

  .fancy-icon {
    -moz-box-shadow: 1px 1px 1px 0 $orange;
    -webkit-box-shadow: 1px 1px 1px 0 $orange;
    box-shadow: 1px 1px 1px 0 $orange;
  }

  a {
    &.default {
      color: $white;

      &:hover,
      &:focus {
        color: $orange;
      }
    }
  }
}

.clouds-gradient{
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

// Title decoration
.title-line {
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    background: $gray-300;
    height: 1px;
    flex: 1;
    content: '';
    margin-left:.75rem;
  }
}

// Ask a Lib Bubble
.ask-lib-bubble-container {
  position: fixed;
  bottom: 23px;
  right: 28px;
  z-index: 1;
  background-color: $white;
  border-radius: $border-radius-circle;

  &.animated {
    animation-duration: 2s;
    animation-iteration-count: 3;
  }

  .ask-lib-bubble {
    display: inline-block;

    img {
      width: 92px;
      height: 92px;
    }
  }
}

.close-bubble{
  position: fixed;
  bottom: 90px;
  right: 26px;
  z-index: 2;
  cursor: pointer;

  i {
    color: #999999;
    font-size: 1.25rem;
  }
}

.feature {
  background-color: $feature-light;
}

.feature-light {
  background-color: $gray-100;
}