// @author Hugo Giraudel
	// Replaces a substring within another string.
	@function str-replace($string, $search, $replace: '') {

		$index: str-index($string, $search);

		@if $index {
			@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
		}

		@return $string;
	}

	// Replaces a substring within each string in a list.
	@function str-replace-all($strings, $search, $replace: '') {

		@each $string in $strings {
			$strings: set-nth($strings, index($strings, $string), str-replace($string, $search, $replace));
		}

		@return $strings;
	}

	// Gets a value from a map.
	@function val($map, $keys...) {

		@if nth($keys, 1) == null {
			$keys: remove-nth($keys, 1);
		}

		@each $key in $keys {
			$map: map-get($map, $key);
		}

		@return $map;
	}