html,
body {
  height:100%;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    font-size: 1.125rem;
  }
}

.body-default {
  margin-top: 76px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin-top: 81px;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 88px;
  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    margin-top: 0;
  }
}

.body-extra-slim {
  margin-top: 54px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 0;
    padding-bottom: 130px;
  }
}

.body-slim {
  margin-top: 76px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 0;
    padding-bottom: 130px;
  }
}



h1, .h1 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 1.75rem;
  }

  &.heading-lg {
    font-size: $font-size-lg;

    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 2rem;
    }
  }
}

h2, .h2 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 1.25rem;
  }
  &.heading-lg {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      font-size:1.5rem;
      line-height: 1.1;
    }
    @media (min-width: map-get($grid-breakpoints, xl2)) {
      font-size:1.75rem;
    }
  }
}

h3, .h3 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 1.125rem;
  }
}

h4, .h4 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 1.125rem;
  }
}

.semibold {
  font-weight: $font-weight-semi;
}

a {
  cursor: pointer;
  @include transition-all;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: $link-bkg-color;
    outline: none;
  }

  &.no-decoration {
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: transparent;
    }


  }

  &.cta {
    border: 1px solid $orange;
    padding: .5rem;
    margin-bottom: .5rem;
    width: auto;
    display: inline-block;
    font-weight: $font-weight-semi;

    i {
      color: $orange;
      margin-left: .25rem;

    }
  }

  &.default {
    color: $body-color;

    &:hover,
    &:focus {
      color: $um-orange;
    }
  }
}

hr {
  border-color: $gray-300;

  &.hr-thick {
    border-top: 2px solid $gray-300;
  }

  &.hr-decoration {
    border-top: 1px solid $gray-300;
    margin-top: 5px;
    margin-bottom: 1rem;
    opacity: 0.5;

    @media (min-width: map-get($grid-breakpoints, xl)) {
      border-top: 3px solid $orange;
      max-width: 50px;
      opacity: 1;
    }
  }

  &.hr-panel {
    border-top: 3px solid $orange;
    max-width: 50px;
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 1rem;
  }
}

.no-padding {
  padding:0;
}

.is-hidden {
  visibility: hidden;
}

.table-no-border {
  td,
  th {
    border-top: none;
  }
}

thead {
  &.th-simple{
    th {
      padding: 0 0 .3rem .5rem;

      h4, h3, h5, h2 {
        margin-bottom: 0;
      }
    }
  }
}


.btn-default {
  color: $white;
  background-color: $orange;
  padding: .5rem 1rem;
  font-weight: $font-weight-semi;
  line-height: $line-height-sm;
  @include transition-all;
  margin-bottom: .75rem;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $um-orange;
    color: $white;
  }
}

.btn-small {
  color: $white;
  background-color: $orange;
  padding: .25rem .5rem;
  font-weight: $font-weight-semi;
  line-height: $line-height-sm;
  @include transition-all;
  margin-bottom: 0;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $um-orange;
    color: $white;
  }
}

.btn-showcase {
  color: $body-color;
  background-color: $white;
  padding: .5rem;
  font-weight: $font-weight-semi;
  line-height: $line-height-sm;
  @include transition-all;
  margin-bottom: .75rem;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $um-orange;
    color: $white;
  }
}

ul,
ol {
  &.list-space {
    li {
      margin-bottom: 1rem;
    }
  }

  &.list-two-columns {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      columns: 2;
    }
  }
}

.fw-semi {
  font-weight: $font-weight-semi;
}

.fs-sm {
  font-size: $font-size-sm !important;
}

.fs-xs {
  font-size: $font-size-mini !important;
}
