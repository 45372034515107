.minimal-header {
  position: relative;

  h1{
    margin-bottom: 0;
    line-height: 1.1;
  }

  i {
    color: $orange;
  }

  hr.hr-panel {
    margin-bottom: 0.75rem;
  }
}

.minimal {
  .minimal-header {
    ul {
      text-align: left;
      font-size: 1rem;
      margin: 0;

      i {
        margin-right: .25rem;
      }
    }

    .page-highlights {
      text-align: left;

      p {
        margin: .5rem 0 0 0;
      }
    }

    h5 {
      margin-bottom: .25rem;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      ul {
        li {
          margin: 0.5rem 2rem 0 0;

          &:last-child {
            margin: 0.5rem 0 0 0;
          }
        }
      }

      .page-highlights {
        text-align: center;
      }
    }

  }
}
