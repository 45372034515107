footer {
  background-color: $lynch;
  padding: 1.25rem 0 1rem 0;
  color: $white;
  margin-bottom: 5.25rem;

  .footer-logo {
    width: 77px;
    height: 60px;
    margin-bottom: 1.25rem;
  }

  h4,
  h6 {
    margin-bottom: .25rem;
  }

  h6 {
    font-weight: $font-weight-normal;
  }

  a {
    color: $white;

    &:hover,
    &:focus {
      color: $orange;
    }
  }

  i {
    font-size: 1.5rem;
    margin-right: .25rem;
  }

  .fdlp-logo {
    width: 95px;
    height: 30px;
    margin-top: 1rem;
  }

  .social {
    margin: 1rem 0;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 1.5rem 0 1rem 0;

    .footer-logo {
      margin-bottom: 0;
    }

    .fdlp-logo {
      margin-top: 0.5rem;
    }

    .social {
      margin: 0;
      text-align: right;
    }

    .supplemental {
      text-align: right;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-bottom: 0;
    position: absolute;
    bottom:0;
    left:0;
    right: 0;
    height: 130px;

    &.sticky-footer{
      position: fixed;
      right:0;
      left: 0;
      bottom: 0;
    }

  }

}