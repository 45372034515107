// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:600,600i|Source+Sans+Pro:400,400i,600,600i,700,700i');

//Custom Sizes, Margins, Paddings
$padding-lg: 					2rem;
$padding-md:                    1.75rem;
$padding-sm:					1.25rem;
$z-index-base:					10000;

//Custom Colors
$um-orange:						#f17430;
$lynch: 						#3a4955;  //4f5b65
$lynch-dark: 					#343d45;
$lib-red: 						#bf4739;
$feature-light: 				#f1f4fc;
$gray-mid:						#333333;
$accent1:						#f05f40; //dark orange
$black:                         #000000;
$dolphin:                       #67a3ad;

// Custom Durations
$duration: (
      transition:   0.2s,
      menu:         0.35s,
      banner:	    2.5s
);

// Bootstrap Overrides.
// -------------------------

// Base Colors
$orange: 						#ff9f55;
$blue: 							#007bff;  //old #21759b
$gray-300: 						#dee2e6;

// Theme Colors
$primary:       				$orange;
$secondary:                     #e9ecef;
$light:							$feature-light;
$dark:							$lynch-dark;

// Body
$body-color: 					$gray-mid;

// Links
$link-color: 					$blue;
$link-decoration:           	underline;
$link-hover-color:          	darken($link-color, 15%);
$link-hover-decoration:     	underline;
$link-bkg-color:                $feature-light;

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  lg2: 1024px,
  xl: 1200px,
  xl2: 1280px,
  xxl: 1400px,
  xl3: 1600px

);

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
        sm: 580px,
        md: 748px,
        lg: 1000px,
        xl: 1280px
);

$spacer: 1rem;

// Fonts
$font-family-sans-serif: 		"Source Sans Pro", Helvetica, sans-serif;
$font-family-alt:				"Montserrat", Helvetica, sans-serif;
$font-family-monospace: 		SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base:              	1rem; // Assumes the browser default, set to 16px
$font-size-lg:                	($font-size-base * 2.625);	//42px
$font-size-sm:                	($font-size-base * .875); //14px
$font-size-mini:				($font-size-base * .75); //12px

$font-weight-light:         	300;
$font-weight-normal:        	400;
$font-weight-bold:          	700;
$font-weight-semi:				600;

$h1-font-size:                	$font-size-base * 2; //32px
$h2-font-size:                	$font-size-base * 1.75;	// 28px
$h3-font-size:                	$font-size-base * 1.5;	//24px
$h4-font-size:                	$font-size-base * 1.25;	//20px
$h5-font-size:                	$font-size-base * 1.125; //18px
$h6-font-size:                	$font-size-base;

$headings-margin-bottom:      	$spacer;
$headings-font-weight:        	600;
$headings-line-height:        	1.2;

// Components
$line-height-lg:              	1.5;
$line-height-sm:              	1.2;

$border-width:                	1px;
$border-color:                	$gray-300;

$border-radius:               	.25rem;
$border-radius-lg:            	3rem;
$border-radius-circle:        	50%;

$card-border-radius:                $border-radius;
$card-border-color:                 $gray-300;
$card-bg:                           transparent;

// Figures
$figure-caption-font-size:          $font-size-base;
$figure-caption-color:              lighten($body-color,10%);

// Tooltips
$tooltip-font-size:           $font-size-base;
$tooltip-max-width:           200px;

// Tables
$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .25rem;

$table-bg:                    transparent;
$table-accent-bg:             rgba(0,0,0,.05);

$table-head-bg:               rgba(0,0,0,.18);
$table-head-color:            $body-color;

// Bootstrap Default Variables.
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
