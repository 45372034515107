// UM Sliver
$sliver-button: #636466;

.slivernav {
  background-color: $black;
  color: $white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  @media (min-width: map-get($grid-breakpoints, xl)) {
    position: relative;
  }
}

.um-svg {
  text-align: center;
  top: -2px;
  height: 18px;

  a {
    text-decoration: none;

    img {
      width: 220px;
      height: 10px;
    }
  }
}

.sliverbutton {
  background-color: $sliver-button;
  border: 1px solid $sliver-button;
  width: 40px;
  cursor: pointer;
  line-height: 1rem;
  font-size: 1rem;
  float: left;
  height: 22px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 50px;
  }

  &:hover {
    background: $sliver-button url($siteurl + "/assets/common-images/btn-over-15.png") 0 0 repeat;

    .custom-eye {
      background: url($siteurl + "/assets/common-images/icon-eye-open.svg") center center no-repeat;
    }
  }

  &.sliver-toggle-menu {
    float: right;
    color: $white;
  }

  .custom-eye {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: url($siteurl + "/assets/common-images/icon-eye-open-white.svg") center center no-repeat;
  }
}

// UM Submenu
.sliver-content {
  background-color: #212121;
  color: $white;
  margin-top: 76px;
  margin-bottom: -76px;
  padding: 1rem;

  form {
    margin-top: .5rem;
  }

  .show-for-sr {
    width: 1px;
    height: 1px;
    overflow: hidden;
    margin: 0;
    display: block;
  }

  .umsearchfield-sm {
    background-color: rgba(255, 255, 255, 0.25);
    border: solid 3px rgba(255, 255, 255, 0.5);
    font-size: .875rem;
    line-height: 1;
    padding: .25rem .75rem;
    width: -webkit-calc(100% - 75px);
    width: -moz-calc(100% - 75px);
    width: calc(100% - 75px);

    &::placeholder {
      color: $white;
    }

    &:focus {
      background-color: rgba(255, 255, 255, 0.9);
      color: $body-color;
    }

  }

  .search-sliver {
    margin-left: .125rem;
    background-color: rgba(255, 255, 255, 0.25);
    border: solid 3px rgba(255, 255, 255, 0.5);
    font-size: .875rem;
    line-height: 1;
    padding: .25rem .5rem;
    color: $white;
  }

  ul {
    li {
      margin-bottom: .5rem;
      font-size: 1rem;
    }
  }

  a {
    color: $white;
    text-decoration: none;
    background-color: transparent;
    font-size: 1rem;

    &:hover{
      color: rgba(255,255,255,0.5);
      text-decoration: none;
      background-color: transparent;
    }

    button {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: $white;
      padding: 0;
      cursor: pointer;
    }
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin-top: 81px;
    margin-bottom: -81px;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {

    padding: 1.5rem 1rem 1rem 1rem;

    form {
      margin-top: 0;
    }

    .umsearchfield-sm {
      width: -webkit-calc(100% - 45px);
      width: -moz-calc(100% - 45px);
      width: calc(100% - 45px);
    }

    a {
      button {
        background-color: #636466;
        border: solid 3px $white;
        font-size:.875rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        padding: .125rem .75rem;
      }

      &.um-last-link {
        margin-left: -20px;
      }
    }

    ul {
      li {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 88px;
    margin-bottom: -88px;
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    margin: 0;
    padding: .75rem;

    a {
      button {
        &.um-last-link {
          margin-left: 0px;
        }
      }
    }

    ul {
      li {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

}

// UM Sliver Accessibility
.sliver-accessibility {
  background-color: #212121;
  color: $white;
  margin-top: 76px;
  margin-bottom: -76px;
  padding: 1rem;

  ul {
    margin-bottom: 0;
    li {
      margin-bottom: .5rem;
      font-size: 1rem;
    }
  }

  a {
    color: $white;
    text-decoration: none;
    background-color: transparent;
    font-size: 1rem;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
      background-color: transparent;
    }
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin-top: 81px;
    margin-bottom: -81px;

    h6 {
      margin-bottom: 0;
    }

    ul {
      li {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 1.5rem 1rem 1rem 1rem;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 88px;
    margin-bottom: -88px;
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    margin: 0;
    padding: .75rem;


  }

}