@media print {

  body {
    background: $white;
    font-size: 12pt;
    font-family:"Times New Roman";
    color: black;
    line-height: $line-height-sm;
    width: 100%;
  }

  h1 {font-size: 20pt; margin: 0; padding: 0 0 5px 0; font-family: inherit;}
  h2 {font-size: 16pt; margin: 0; padding: 0 0 5px 0; font-family: inherit;}
  h3 {font-size: 14pt; margin: 0; padding: 0 0 5px 0; font-family: inherit;}
  h4 {font-size: 12pt; margin: 0; padding: 0 0 5px 0; font-family: inherit;}
  h5 {font-size: 12pt; margin: 0; padding: 0 0 5px 0; font-family: inherit;}
  p {font-size: 12pt; margin: 0; padding: 0 0 5px 0; font-family: inherit;}

  div,
  section,
  .section {
    width: 100%;
    border: none;
    margin: 0 ;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background: white;
    text-align: left;
    font-family: inherit;
    font-size: 12pt;
    float: none !important;
    overflow: visible !important;
    position: relative !important;
  }

  img {
    border: none;
  }

  ul, ol {margin: 0 0 0 30px;}

  a,
  a:link,
  a:visited {
    color: #000;
    text-decoration: underline;
    border: none;
  }


  p a[href^="http://"]:after,
  p a[href^="https://"]:after {
    content: " (" attr(href) ")";
    font-size: 80%;
    text-decoration: underline;
  }

  a[href^="http://"]:after,
  a[href^="https://"]:after {
    content: " (" attr(href) ")";
    font-size: 80%;
    text-decoration: underline;
  }

  p a[href^="#"]:after, a[href^="#"]:after {
    display: none;
  }

  a[href^="#"] {
    text-decoration: none;
  }

  a[name] {display: none;}

  a[name]:after {
    display: none;
  }

  p a {
    word-wrap: break-word;
  }

  em {font-style: italic;}


  //Hide unneeded elements from interface
  .slivernav,
  .site-navbar,
  footer,
  .site-toolbar,
  #left,
  .search-area,
  .favorite-heart,
  #colorbox,
  #cboxContent,
  #cboxOverlay,
  form,
  .page-header-img {
    display: none !important; visibility: hidden; height: 0; background: transparent;
  }
}