@charset "UTF-8";
$siteurl: 'https://www.library.miami.edu';
@import 'libs/vars';
@import 'libs/fontawesome';
@import 'libs/functions';
@import 'libs/mixins';

//Bootstrap 4.3.1 All
//@import 'bootstrap4/scss/bootstrap';

//Bootstrap 4.3.1 Parts
@import 'bootstrap4/scss/functions';
@import 'bootstrap4/scss/variables';
@import 'bootstrap4/scss/mixins';
@import "bootstrap4/scss/root";
@import "bootstrap4/scss/reboot";
@import "bootstrap4/scss/type";
@import "bootstrap4/scss/images";
//@import "bootstrap4/scss/code";
@import "bootstrap4/scss/grid";
//@import "bootstrap4/scss/tables";
@import "bootstrap4/scss/forms";
@import "bootstrap4/scss/buttons";
@import "bootstrap4/scss/transitions";
//@import "bootstrap4/scss/dropdown";
//@import "bootstrap4/scss/button-group";
//@import "bootstrap4/scss/input-group";
//@import "bootstrap4/scss/custom-forms";
@import "bootstrap4/scss/nav";
@import "bootstrap4/scss/navbar";
//@import "bootstrap4/scss/card";
//@import "bootstrap4/scss/breadcrumb";
//@import "bootstrap4/scss/pagination";
//@import "bootstrap4/scss/badge";
//@import "bootstrap4/scss/jumbotron";
//@import "bootstrap4/scss/alert";
//@import "bootstrap4/scss/progress";
//@import "bootstrap4/scss/media";
//@import "bootstrap4/scss/list-group";
//@import "bootstrap4/scss/close";
//@import "bootstrap4/scss/modal";
//@import "bootstrap4/scss/tooltip";
//@import "bootstrap4/scss/popover";
//@import "bootstrap4/scss/carousel";
@import "bootstrap4/scss/utilities";
//@import "bootstrap4/scss/spinners";
//@import "bootstrap4/scss/toasts";
@import "bootstrap4/scss/print";


//Font-Awesome 5.0.6
@import 'scss/fontawesome';
@import 'scss/fa-solid';
@import 'scss/fa-regular';
@import 'scss/fa-brands';

// Custom - Base
@import 'base/base';
@import 'base/utilities';

// Custom - Layout
@import 'layout/section';
@import 'layout/umsliver';
@import 'layout/site-header';
@import 'layout/js-offcanvas.settings';
@import 'layout/js-offcanvas.mixins';
@import 'layout/js-offcanvas';
@import 'layout/mega-menus';
@import 'layout/site-footer';
@import 'layout/site-toolbar';

@import 'layout/minimal-header';

// Custom Elements
@import 'external/custom';

// Print
@import 'base/print';