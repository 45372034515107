.mega-menu {
  font-size: 1rem;

  .section {
    padding: $padding-sm 0 $padding-sm 0.75rem;

    h5 {
      margin-bottom: 0;
      font-size: 1rem;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 1.5rem;

      .dropdown-item {
        padding: .25rem 0 .25rem .75rem;
        @include no-transition;
        color: $white;
        white-space: normal;
        line-height: $line-height-sm;

        &:hover,
        &:focus {
          color: $orange;
        }

        &.cta {
          padding: .5rem;
          width: auto;
          border: none;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
          padding: .25rem 0;
          color: $body-color;
          display: inline-block;
          width: auto;

          &:hover,
          &:focus {
            color: $um-orange;
          }

          &.cta {
            border: 1px solid $orange;
          }
        }
      }

    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      padding: $padding-md 0 $padding-sm 0;

      .container {
        position: relative;
      }


      h5 {
        font-size: 1.125rem;

        &.card-title {
          margin-bottom:.75rem;
        }
      }
    }
  }

  .card {
    border: none;
    border-radius:0;

    p {
      line-height: $line-height-sm;
    }

    .card-body {
      padding: 0;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      border: 1px solid $card-border-color;
      border-radius: $card-border-radius;
      margin-bottom: 1rem;
      margin-right: 1.25rem;
      background-color: $white;

      .card-body {
        padding: .75rem;

        ul {
          &:last-of-type {
            margin-bottom: 0;
          }

          .dropdown-item {
            padding: .25rem 0;
          }
        }
      }
    }

  }

  &.account-dropdown {
    position: absolute;
    top: 100%;
    height: 100vh;
    width: 100%;
    display: none;
    color: $body-color;

    &.show {
      display: none;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      background-color: $gray-100;
      box-shadow: 0 2px 2px -2px $gray-600;
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;
      height: auto;

      &.show {
        display: block;
      }
      .section {
        padding: $padding-md 0 $padding-sm 0;
      }

      h5 {
        font-size: 1.125rem;
      }

      ul {
        .dropdown-item {
          color: $body-color;
          padding: .25rem 0;
          display: inline-block;
          width: auto;

          &.cta {
            color: $body-color;
            border: 1px solid $orange;
          }
        }
      }

    }

  }

  .mm-lib {

    a {
      font-weight: $font-weight-semi;
      color: $white;

      @media (min-width: map-get($grid-breakpoints, xl)) {
        color: $body-color;
      }

    }

    .nested-items {
      margin-bottom: .5rem;
    }

    .mm-lib-time{
      font-weight: $font-weight-normal;
      padding-left: 1.25rem;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      .mm-lib-img {
        width: 105px;
        height: 70px;
        margin-right: 1rem;
        border: 1px solid $border-color;
      }

      .nested-items{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: -webkit-calc(100% - 125px);
        width: -moz-calc(100% - 125px);
        width: calc(100% - 125px);
        margin-bottom: 0;
      }

      a,
      .mm-lib-time {
        width: 100%;
      }

      a {
        font-weight: $font-weight-normal;
      }

      .mm-lib-time{
        font-weight: $font-weight-semi;
        padding-left: 0;
      }
    }
  }

}

// Slim NoSliver Mega menu Dropdowns
.site-navbar-slim-nosliver,
.site-navbar-slim {
  .mega-menu {
    .section {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: $padding-md 0 $padding-sm 0;

        .container {
          position: relative;
        }


        h5 {
          font-size: 1.125rem;

          &.card-title {
            margin-bottom:.75rem;
          }
        }
      }

      ul {
        .dropdown-item {
          @media (min-width: map-get($grid-breakpoints, lg)) {
            padding: .25rem 0;
            color: $body-color;
            display: inline-block;
            width: auto;

            &:hover,
            &:focus {
              color: $um-orange;
            }

            &.cta {
              border: 1px solid $orange;
              padding: .5rem;
            }
          }
        }
      }
    }

    .card {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        border: 1px solid $card-border-color;
        border-radius: $card-border-radius;
        margin-bottom: 1rem;
        margin-right: 1.25rem;
        background-color: $white;

        .card-body {
          padding: .75rem;

          ul {
            &:last-of-type {
              margin-bottom: 0;
            }

            .dropdown-item {
              padding: .25rem 0;
            }
          }
        }
      }
    }

    .mm-lib {
      a {
        @media (min-width: map-get($grid-breakpoints, lg)) {
          color: $body-color;

          &:hover,
          &:active,
          &:focus {
            @include transition-all();
            color: $um-orange;
          }
        }
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .mm-lib-img {
          width: 105px;
          height: 70px;
          margin-right: 1rem;
          border: 1px solid $border-color;
        }

        .nested-items{
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          width: -webkit-calc(100% - 125px);
          width: -moz-calc(100% - 125px);
          width: calc(100% - 125px);
          margin-bottom: 0;
        }

        a,
        .mm-lib-time {
          width: 100%;
        }

        a {
          font-weight: $font-weight-normal;
        }

        .mm-lib-time{
          font-weight: $font-weight-semi;
          padding-left: 0;
        }
      }
    }
  }
}

