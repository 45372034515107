.site-toolbar {
  position: fixed;
  right:0;
  left: 0;
  bottom: 0;
  background-color: $lynch-dark;
  color: $white;
  padding: 1rem 0;
  z-index: 2;

  .nav-tabs {
    .nav-link {
      &.active {
        color: $orange;
        background-color: transparent;
        border:none;
      }
    }

    .nav-item {
      &.show {
        color: $orange;
        background-color: transparent;
        border:none;
      }
    }
  }

  ul {
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    border: none;

    li {
      text-align: center;
      font-size: 1rem;

      &.nav-item {
        margin-bottom: 0;
      }

      a {
        &.nav-link {
          padding:0;
          border: none;
          border-radius: 0;
          color: $white;
          @include no-transition;
          display: inline-block;

          &:hover,
          &:focus,
          &.active {
            color: $orange;
            background-color: transparent;
            border:none;
          }
        }

        &.toolbar-search,
        &.toolbar-hours {
          img {
            width: 24px;
            height: 24px;
            display: block;
            margin-bottom: 0.25rem;
            margin-left: 10px;
          }
        }

        &.toolbar-account {
          img {
            width: 34px;
            height: 24px;
            display: block;
            margin-bottom: 0.25rem;
            margin-left: 13px;
          }
        }

        &.toolbar-ask {
          img {
            width: 28px;
            height: 24px;
            display: block;
            margin-bottom: 0.25rem;
            margin-left: 24px;
          }
        }
      }

      i {
        display: block;
        margin-bottom: 0.25rem;
        font-size: 1.5rem;
      }

    }
  }

  .toolbar-container {
    position: relative;
    color: $body-color;

    .tab-content {
      position: absolute;
      bottom: 100%;
      width: 100%;
      margin-bottom: 1rem;

      .tab-pane {
        height: -webkit-calc(100vh - 160px);
        height: -moz-calc(100vh - 160px);
        height: calc(100vh - 160px);
        background-color: $gray-100;
        overflow: auto;
        line-height: $line-height-sm;

        h5 {
          margin-bottom: 0;
        }

        ul {
          display: block;
          height: auto;

          li {
            text-align: left;
            margin: 0;
          }
        }

        a {
          color: $body-color;
          padding: .25rem 0;
          display: block;

          &:hover,
          &:focus {
            color: $um-orange;
          }

          &.cta {
            border: none;
            margin-bottom: 0;
            width: 100%;

            i {
              display: none;
            }
          }
        }
      }

    }
  }
}